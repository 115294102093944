import { module } from 'modujs';
import { html } from '../utils/environment';


const CLASS = {
    CREDITMOVE: `has-credit-moved`
}

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
    }

    creditMove(obj){
        if (obj.way == "enter") {
            html.classList.add(CLASS.CREDITMOVE)
        } else if(obj.way == "exit") {
            html.classList.remove(CLASS.CREDITMOVE)
        }
    }

    destroy() {
        html.classList.remove(CLASS.CREDITMOVE)
    }
}
