import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html } from './utils/environment';

const app = new modular({
    modules: modules
});

window.isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

app.init(app);
globals();

html.classList.add('is-loaded');
html.classList.add('is-ready');
html.classList.remove('is-loading');
