import { module } from 'modujs';
import modularLoad from 'modularload';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        html.classList.add('is-first-load');

        this.load = new modularLoad({
            enterDelay: 600,
            transitions: {
                customTransition: {}
            }
        });

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            let color = Math.floor(Math.random() * 5) + 1  ;
            html.setAttribute('data-transition-color', color);

            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');

            //setTimeout(() => {
                html.classList.remove('is-changing-page')
           // },1000)

        });

        this.load.on('loading', (transition, oldContainer) => {
            html.classList.add('is-changing-page')
            html.classList.remove('has-credit-open')
            html.classList.remove('has-nav-open')
        })

        this.load.on('images', () => {
            this.call('update', 'Scroll')
        })
    }
}
