import { module } from 'modujs';
import { html } from '../utils/environment';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);

        window.scrollObj = {
            scroll: {
                y:0, x:0
            },
            limit: 0
        }
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: false,
            class: 'is-inview',
            getDirection: true
        });

        window.scroll = this.scroll;

        this.scroll.on('call', (func,way,obj) => {
            this.call(func[0],{way,obj},func[1],func[2]);
        })

        this.scroll.on('scroll', (obj) => {
            window.scrollObj = obj
        })
    }

    update() {
        if(this.scroll && this.scroll.update) this.scroll.update();
    }

    scrollTo(obj) {
        this.scroll.scrollTo(obj.target, obj.offset);
    }

    destroy() {
        this.scroll.destroy();
    }
}
