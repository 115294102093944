import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        if(!window.isMobile){
            this.initDrag()
        }
    }

    /**
     * Draggabilly library by desandro
     * see docs right here `https://draggabilly.desandro.com/`
     */
    initDrag() {
        this.drag = new Draggabilly(this.el)
    }

    destroy() {
        if(!window.isMobile){
            this.drag.destroy()
        }
    }
}
