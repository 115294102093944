import { module } from 'modujs';
import {getData} from '../utils/html';

const SELECTOR = {
    ITEM: `.js-accordion-item`,
    TRIGGERER: `.js-accordion-triggerer`,
    CONTENT: `.js-accordion-content`,
    WRAP: `.js-accordion-wrap`
};

const CLASS = {
    OPEN: `is-open`
};

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                triggerer: 'trigger'
            }
        }

        this.openIndex = getData(this.getData('open-index'))

        if(this.openIndex != null) this.open(Array.from(this.el.querySelectorAll(SELECTOR.ITEM))[this.openIndex], false)
    }

    init() {
        if(this.getData('scroll') === "false") {
            this.canScroll = false
        } else {
            this.canScroll = true
        }
    }

    /**
     * Trigger
     *
     * @param {event}
     */
     trigger(e) {
        let accordionTriggerer = e.currentTarget
        let accordionItem      = accordionTriggerer.closest(SELECTOR.ITEM)

        if(accordionItem.classList.contains(CLASS.OPEN))
            this.close(accordionItem)
        else
            this.open(accordionItem, true)
     }

    /**
     * Open
     *
     * @param {node} The accordion you wish to open
     */
     open(el, animate) {
        this.checkActive()

        if(!animate) {
            TweenMax.set(el.querySelector(SELECTOR.WRAP), { height: Math.round(this.getBounds(el.querySelector(SELECTOR.CONTENT))) })
        } else {
            // $(el.querySelector(SELECTOR.CONTENT)).slideDown(300, () => this.scrollTo(el));
            TweenMax.to(el.querySelector(SELECTOR.WRAP), 0.3, {
                height: Math.round(this.getBounds(el.querySelector(SELECTOR.CONTENT))),
                onComplete: () => {
                    this.scrollTo(el)
                }
            })
        }

        el.classList.add(CLASS.OPEN)
     }

    /**
    * Close
    *
    * @param {jQuery object} The $accordion you wish to close
    */
    close(el) {
        TweenMax.to(el.querySelector(SELECTOR.WRAP), 0.3, {
            height: 0,
            onComplete: () => {
                if(this.canScroll) this.call('update', null, 'Scroll', 'main')
            }
        })

        el.classList.remove(CLASS.OPEN)
    }

    /**
     * Check for already opened accordion then close it
     */
    checkActive() {
        let activeAccordion = this.el.querySelector(SELECTOR.ITEM+'.'+CLASS.OPEN)

        if(activeAccordion != null) this.close(activeAccordion)
    }

    /**
     * Get bounds of content
     */
    getBounds(el) {
        return el.getBoundingClientRect().height
    }

    /**
    * scrollTo
    *
    * @param {el} Used for scrollTo target
    * @return
    */
    scrollTo(scrollTarget) {
        if(this.canScroll) {
            this.call('update', null, 'Scroll', 'main')
            this.call('scrollTo', {target: scrollTarget, offset: 0}, 'Scroll', 'main')
        }
    }
}
