import { module } from 'modujs';
import { html } from '../utils/environment';


const CLASS = {
    HEADEROVER: `has-header-over`
}

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
    }

    headerOver(obj){
        if (obj.way == "enter") {
            html.classList.add(CLASS.HEADEROVER)
        } else if(obj.way == "exit") {
            html.classList.remove(CLASS.HEADEROVER)
        }
    }

    destroy() {
        html.classList.remove(CLASS.HEADEROVER)
    }
}
