import { module } from 'modujs';
import { html } from '../utils/environment'

const CLASS = {
    HOVER: `has-logo-hovered`
};

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            mouseenter: {
                logo: 'hover'
            },
            mouseleave: {
                logo: 'leave'
            }
        }
    }

    init() {

    }

    hover() {
        html.classList.add(CLASS.HOVER)
    }

    leave() {
        html.classList.remove(CLASS.HOVER)
    }

}
