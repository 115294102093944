import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.colors = this.el.getAttribute('data-colors').split(',');

        this.items = Array.from(this.$('item'))

        for (let i = this.items.length - 1; i >= 0; i--) {
            let randColor = this.colors[Math.floor(Math.random() * this.colors.length)]
            this.items[i].style.backgroundColor = randColor;
        }
    }
}
